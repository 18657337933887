/**
 * Resource method to update the password of the user that is currently signed-in.
 *
 * @module resources/users/update-my-password
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/users/update-my-password~Params
 * @type {object}
 * @property {string} currentPassword - The current password.
 * @property {string} newPassword - The new password.
 */

/**
 * Updates the password of the user that is currently signed-in based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/users/update-my-password~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const updatePassword = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'put',
    url: '/users/me/password',
  }).then((response) => {
    return response.data;
  });
};

export default updatePassword;
