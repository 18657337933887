/**
 * Password input.
 */
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useMemo, useState } from 'react';

import HiddenIcon from './images/hidden-icon.png';
import VisibleIcon from './images/visible-icon.png';

import './password-input.scss';

const PasswordInput = memo((props) => {
  const { placeholder, maxLength, label, id, error, value, onChange, disabled } = props;

  const [ visible, setVisible ] = useState(false);

  // Function that is invoked when the visibility of the password input change
  const handleVisibilityClick = useCallback(() => {
    setVisible(!visible);
  }, [ visible ]);

  // The password visibility image source
  const imageSource = useMemo(() => {
    return visible ? VisibleIcon : HiddenIcon;
  }, [ visible ]);

  // The type of password input
  const passwordInputType = useMemo(() => {
    return visible ? 'text' : 'password';
  }, [ visible ]);

  return (
    <div
      className='ody-password-input__container'
    >
      <label
        htmlFor={ id }
      >
        { label }
      </label>
      <div className={
        clsx('ody-password-input__input-box', {
          'ody-password-input__input-box--disabled': disabled,
          'ody-password-input__input-box--error': error,
        })
      }
      >
        <input
          className='ody-password-input__input'
          disabled={ disabled }
          id={ id }
          maxLength={ maxLength }
          onChange={ onChange }
          placeholder={ placeholder }
          type={ passwordInputType }
          value={ value }
        />
        <button
          className={
            clsx('ody-password-input__visibility-button', {
              'ody-password-input__visibility-button--disabled': disabled,
            })
          }
          disabled={ disabled }
          onClick={ handleVisibilityClick }
          type='button'
        >
          <img
            alt='visibility icon'
            src={ imageSource }
          />
        </button>
      </div>
    </div>
  );
});

PasswordInput.displayName = 'PasswordInput';

PasswordInput.propTypes = {
  // Whether the input is disabled
  disabled: PropTypes.bool,
  // The error message of the input
  error: PropTypes.bool.isRequired,
  // The id of the input
  id: PropTypes.string.isRequired,
  // The label of the input
  label: PropTypes.string.isRequired,
  // The maxLength property of the input
  maxLength: PropTypes.number.isRequired,
  // The onchange function that is triggered when the input value is changing
  onChange: PropTypes.func.isRequired,
  // The input placeholder
  placeholder: PropTypes.string.isRequired,
  // The value of the input
  value: PropTypes.string.isRequired,
};

PasswordInput.defaultProps = {};

export default PasswordInput;
