import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import PublicFAQCollection from './public-faqs-collection/public-faqs-collection';

import './public-faq-section.scss';

const PublicFAQSection = memo((props) => {
  const { title, faqs, isRed } = props;

  return (
    <div
      className={
        clsx('public-faq-section', {
          'public-faq-section--red': isRed,
        })
      }
    >
      <h2 className='hdg hdg-xxl public-faq-section__title'>
        { title }
      </h2>
      <PublicFAQCollection
        faqs={ faqs }
      />
    </div>
  );
});

PublicFAQSection.displayName = 'PublicFAQSection';

PublicFAQSection.propTypes = {
  // The faqs array
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      // The answer
      answer: PropTypes.string,
      // The question
      question: PropTypes.string,
    })
  ).isRequired,
  // Whether the section is red
  isRed: PropTypes.bool,
  // The section title
  title: PropTypes.string.isRequired,
};

PublicFAQSection.defaultProps = {
  isRed: false,
};

export default PublicFAQSection;
