/**
 * Internationalization.
 *
 * @module i18n
 */

/**
 * The supported languages.
 *
 * @constant {string[]}
 * @default
 * @static
 */
export const LANGUAGES = [
  'el',
  'en',
];

/**
 * The default language.
 *
 * @constant {string}
 * @default
 * @static
 */
export const DEFAULT_LANGUAGE = 'en';
