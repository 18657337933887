/**
 * Duck for job seekers.
 *
 * @module ducks/job-seekers
 */
import filterTypes from 'utilities/job-seekers/filter-types';

/**
 * The type of actions to describe that a search was completed (successfully or not).
 *
 * @constant {string}
 * @default
 * @static
 */
const JOB_SEEKER_SEARCH_COMPLETED = 'JOB_SEEKER_SEARCH_COMPLETED';

/**
 * The type of actions to describe that a search was made.
 *
 * @constant {string}
 * @default
 * @static
 */
const JOB_SEEKER_SEARCH_MADE = 'JOB_SEEKER_SEARCH_MADE';

/**
 * The type of actions to describe that a search was refined.
 *
 * @constant {string}
 * @default
 * @static
 */
const JOB_SEEKER_SEARCH_REFINED = 'JOB_SEEKER_SEARCH_REFINED';

/**
 * The type of actions to describe that everything related to job seeker searches was reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const JOB_SEEKER_SEARCH_RESET = 'JOB_SEEKER_SEARCH_RESET';

/**
 * The type of actions to describe that the job seekers statistics was set.
 *
 * @constant {string}
 * @default
 * @static
 */
const JOB_SEEKERS_STATISTICS_SET = 'JOB_SEEKERS_STATISTICS_SET';

/**
 * Criteria.
 *
 * @typedef module:ducks/job-seekers~Criterion
 * @type {object}
 * @property {('CITY' | 'JOB_SECTOR' | 'TERM')} type - The type of the criterion.
 * @property {string} value - The value of the criterion.
 */

/**
 * Filters.
 *
 * @typedef module:ducks/job-seekers~Filter
 * @type {object}
 * @property {('COMPUTER_SKILLS_LEVEL' | 'DRIVING_LICENSE_CATEGORY' | 'EXPERIENCE_LENGTH' | 'LANGUAGE'
 *   | 'PROFESSION' | 'PROFILE_COMPLETION_PERCENTAGE' | 'QUALIFICATION_TYPE' | 'WILLING_TO_RELOCATE'
 *   | 'WORK_AVAILABILITY')} type - The type of the filter.
 * @property {string} value - The value of the filter.
 */

/**
 * Search result.
 *
 * @typedef module:ducks/job-seekers~Result
 * @type {object}
 * @property {module:types/job-seekers/searches~Facets} [facets] - The facets.
 * @property {module:types/common~Meta} [meta] - The pagination metadata.
 * @property {module:types/job-seekers/profiles~Profile[]} [profiles] - The profiles.
 */

/**
 * Creates an action to complete a search with the given result.
 *
 * @param {module:ducks/job-seekers~Result} result - The search result.
 * @returns {object} - The action.
 * @static
 */
const completeSearch = (result) => {
  return {
    result,
    type: JOB_SEEKER_SEARCH_COMPLETED,
  };
};

/**
 * Search parameters.
 *
 * @typedef module:ducks/job-seekers~Params
 * @type {object}
 * @property {module:ducks/job-seekers~Criterion[]} criteria - The criteria.
 * @property {module:ducks/job-seekers~Filter[]} [filters] - The filters.
 * @property {number} page - The number of the current page.
 * @property {('LAST_MODIFIED_AT' | 'PROFILE_COMPLETION' | 'WORK_AVAILABILITY')} sort - The key to sort by.
 */

/**
 * Creates an action to make a search with the given parameters.
 *
 * @param {module:ducks/job-seekers~Params} params - The search parameters.
 * @returns {object} - The action.
 * @static
 */
const makeSearch = (params) => {
  return {
    params,
    type: JOB_SEEKER_SEARCH_MADE,
  };
};

/**
 * Creates an action to refine a search with the given parameters.
 *
 * @param {module:ducks/job-seekers~Params} params - The search parameters.
 * @returns {object} - The action.
 * @static
 */
const refineSearch = (params) => {
  return {
    params,
    type: JOB_SEEKER_SEARCH_REFINED,
  };
};

/**
 * Creates an action to set the statistics of the job seekers based on the given params.
 *
 * @param {module:types/job-seekers/statistics~Statistics} statistics - The profile.
 * @returns {object} - The action.
 * @static
 */
const setStatistics = (statistics) => {
  return {
    statistics,
    type: JOB_SEEKERS_STATISTICS_SET,
  };
};

/**
 * Creates an action to reset everything related to job seeker searches.
 *
 * @returns {object} - The action.
 * @static
 */
const resetSearch = () => {
  return {
    type: JOB_SEEKER_SEARCH_RESET,
  };
};

/**
 * Job seekers state.
 *
 * @typedef module:ducks/job-seekers~State
 * @type {object}
 * @property {module:ducks/job-seekers~Criterion[]} [criteria] - The criteria.
 * @property {module:types/job-seekers/searches~Facets} [facets] - The facets.
 * @property {module:ducks/job-seekers~Filter[]} [filters] - The filters.
 * @property {module:types/common~Meta} [meta] - The pagination metadata.
 * @property {number} [page] - The number of the current page.
 * @property {module:types/job-seekers/profiles~Profile[]} [profiles] - The profiles.
 * @property {('LAST_MODIFIED_AT' | 'PROFILE_COMPLETION' | 'WORK_AVAILABILITY')} [sort] - The key to sort by.
 * @property {module:types/job-seekers/statistics~Statistics} [statistics] - The job seekers statistics.
 */

/**
 * The initial job seekers state.
 *
 * @type {module:ducks/job-seekers~State}
 * @static
 */
const initialState = {
  criteria: undefined,
  expanded: filterTypes.reduce((acc, ft) => {
    return { ...acc, [ft]: true };
  }, {}),
  facets: undefined,
  filters: undefined,
  meta: undefined,
  page: undefined,
  profiles: undefined,
  sort: undefined,
  statistics: undefined,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/job-seekers~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/job-seekers~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case JOB_SEEKER_SEARCH_COMPLETED:
    return {
      ...state,
      facets: action.result.facets,
      meta: action.result.meta,
      profiles: action.result.profiles,
    };
  case JOB_SEEKER_SEARCH_MADE:
    return {
      criteria: action.params.criteria,
      expanded: filterTypes.reduce((acc, ft) => {
        return { ...acc, [ft]: true };
      }, {}),
      facets: undefined,
      filters: action.params.filters,
      meta: undefined,
      page: action.params.page,
      profiles: undefined,
      sort: action.params.sort,
    };
  case JOB_SEEKER_SEARCH_REFINED:
    return {
      ...state,
      criteria: action.params.criteria,
      filters: action.params.filters,
      page: action.params.page,
      sort: action.params.sort,
    };
  case JOB_SEEKERS_STATISTICS_SET:
    return {
      ...state,
      statistics: action.statistics,
    };
  case JOB_SEEKER_SEARCH_RESET:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const actions = {
  completeSearch,
  makeSearch,
  refineSearch,
  resetSearch,
  setStatistics,
};

const types = {};

export {
  actions,
  reducer,
  types,
};
