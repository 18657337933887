/**
 * Resource method to get the user profile from Google.
 *
 * @module resources/users/get-google-profile
 */
import env from 'config/env';

/**
 * Params.
 *
 * @typedef module:resources/users/get-google-profile~Params
 * @type {object}
 * @property {string} accessToken - The accessToken via Google.
 * @property {string} tokenType - The token type via Google.
 */

/**
 * Result.
 *
 * @typedef {module:types/users~UserGoogleProfile} Result
 */

/**
 * Gets the user's Google profile.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 * @param {module:resources/users/get-google-profile~Params} params - The parameters.
 * @returns {Promise<module:resources/users/get-google-profile~Result>} - The promise.
 * @static
 */
const getUsersGoogleProfile = (params) => {
  return fetch(env.GOOGLE_USER_INFO_URL, {
    headers: {
      'Authorization': `${ params.tokenType } ${ params.accessToken }`,
    },
  })
    .then((response) => {
      return response.json();
    });
};

export default getUsersGoogleProfile;
