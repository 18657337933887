/**
 * Filter types.
 *
 * @module utilities/job-seekers/filter-types
 */

/**
 * Computer skills levels.
 *
 * @constant {string}
 * @static
 */
export const COMPUTER_SKILLS_LEVEL = 'COMPUTER_SKILLS_LEVEL';

/**
 * Driving license categories.
 *
 * @constant {string}
 * @static
 */
export const DRIVING_LICENSE_CATEGORY = 'DRIVING_LICENSE_CATEGORY';

/**
 * Experience length.
 *
 * @constant {string}
 * @static
 */
export const EXPERIENCE_LENGTH = 'EXPERIENCE_LENGTH';

/**
 * Languages.
 *
 * @constant {string}
 * @static
 */
export const LANGUAGE = 'LANGUAGE';

/**
 * Professions.
 *
 * @constant {string}
 * @static
 */
export const PROFESSION = 'PROFESSION';

/**
 * Profile completion percentage.
 *
 * @constant {string}
 * @static
 */
export const PROFILE_COMPLETION_PERCENTAGE = 'PROFILE_COMPLETION_PERCENTAGE';

/**
 * Qualification types.
 *
 * @constant {string}
 * @static
 */
export const QUALIFICATION_TYPE = 'QUALIFICATION_TYPE';

/**
 * Qualification types.
 *
 * @constant {string}
 * @static
 */
export const WILLING_TO_RELOCATE = 'WILLING_TO_RELOCATE';

/**
 * Work availabilities.
 *
 * @constant {string}
 * @static
 */
export const WORK_AVAILABILITY = 'WORK_AVAILABILITY';

export default [
  COMPUTER_SKILLS_LEVEL,
  DRIVING_LICENSE_CATEGORY,
  EXPERIENCE_LENGTH,
  LANGUAGE,
  PROFESSION,
  PROFILE_COMPLETION_PERCENTAGE,
  QUALIFICATION_TYPE,
  WILLING_TO_RELOCATE,
  WORK_AVAILABILITY,
];
