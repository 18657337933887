/**
 * Environment.
 *
 * @module config/env
 */

/**
 * The core base URL.
 *
 * This constant gets its value from the `REACT_APP_CORE_BASE_URL` environment variable.
 *
 * @constant {string}
 * @default 'http://localhost:8080'
 * @static
 */
const CORE_BASE_URL = process.env['REACT_APP_CORE_BASE_URL'] || 'http://localhost:8080';

/**
 * The Google client ID.
 *
 * This constant gets its value from the `REACT_APP_GOOGLE_CLIENT_ID` environment variable.
 *
 * @constant {string}
 * @default ''
 * @static
 */
const GOOGLE_CLIENT_ID = process.env['REACT_APP_GOOGLE_CLIENT_ID'] || '';

/**
 * The Google user profile info url.
 *
 * This constant gets its value from the `REACT_APP_USER_INFO_URL` environment variable.
 *
 * @constant {string}
 * @default ''
 * @static
 */
const GOOGLE_USER_INFO_URL = process.env['REACT_APP_USER_INFO_URL'] || '';

/**
 * The LinkedIn client ID.
 *
 * This constant gets its value from the `REACT_APP_LINKEDIN_CLIENT_ID` environment variable.
 *
 * @constant {string}
 * @default ''
 * @static
 */
const LINKEDIN_CLIENT_ID = process.env['REACT_APP_LINKEDIN_CLIENT_ID'] || '';

/**
 * The application public URL.
 *
 * This constant gets its value from the `REACT_APP_PUBLIC_URL` environment variable.
 *
 * @constant {string}
 * @default 'http://localhost:3000'
 * @static
 */
const PUBLIC_URL = process.env['REACT_APP_PUBLIC_URL'] || 'http://localhost:3000';

/**
 * The recaptcha key.
 *
 * This constant gets its value from the `REACT_APP_RECAPTCHA_SITE_KEY` environment variable.
 *
 * @constant {string}
 * @static
 */
const RECAPTCHA_KEY = process.env['REACT_APP_RECAPTCHA_SITE_KEY'] || '';

/**
 * The request timeout.
 *
 * This constant gets its value from the `REACT_APP_REQUEST_TIMEOUT` environment variable.
 *
 * @constant {number}
 * @static
 */
const REQUEST_TIMEOUT = parseInt(process.env['REACT_APP_REQUEST_TIMEOUT']) || 10;

/**
 * The upload timeout in seconds.
 *
 * This constant gets its value from the `REACT_APP_UPLOAD_TIMEOUT` environment variable.
 *
 * @constant {number}
 * @static
 */
const UPLOAD_TIMEOUT = parseInt(process.env['REACT_APP_UPLOAD_TIMEOUT']) || 60;

export default {
  CORE_BASE_URL,
  GOOGLE_CLIENT_ID,
  GOOGLE_USER_INFO_URL,
  LINKEDIN_CLIENT_ID,
  PUBLIC_URL,
  RECAPTCHA_KEY,
  REQUEST_TIMEOUT,
  UPLOAD_TIMEOUT,
};
