import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useState } from 'react';

import ArrowUpIcon from 'components/common/icons/arrow-up-icon/arrow-up-icon';
import useElementOnScreen from 'hooks/use-element-on-screen';

import './public-faq-item.scss';

const PublicFAQItem = memo((props) => {
  const { answer, question } = props;

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  // Whether the accordion is expanded
  const [ expanded, setExpanded ] = useState(true);

  // handle toggle expansion
  const handleToggleExpansion = useCallback(() => {
    setExpanded(!expanded);
  }, [ expanded ]);

  return (
    <div
      className={
        clsx('public-faq-item', {
          'public-faq-item__expanded': expanded,
          'public-faq-item__visible': isVisible,
        })
      }
      ref={ containerRef }
    >
      <button
        className='txt txt-lg txt-bold public-faq-item__question-toggle'
        id='toggle-accordion'
        name='toggle accordion'
        onClick={ handleToggleExpansion }
      >
        { question }
        <div
          className={
            clsx('public-faq-item__question-toggle-icon-container', {
              'public-faq-item__question-toggle-icon-container--expanded': expanded,
            })
          }
        >
          <ArrowUpIcon />
        </div>
      </button>
      <div
        className={
          clsx('public-faq-item__answer-container', {
            'public-faq-item__answer-container--expanded': expanded,
          })
        }
      >
        <p className='txt txt-md public-faq-item__answer'>
          { answer }
        </p>
      </div>
    </div>
  );
});

PublicFAQItem.displayName = 'PublicFAQItem';

PublicFAQItem.propTypes = {
  // Thw answer to the question
  answer: PropTypes.string.isRequired,
  // The question
  question: PropTypes.string.isRequired,
};

export default PublicFAQItem;
