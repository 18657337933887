/**
 * Toasts.
 *
 * @module toasts
 */
import { toastr } from 'react-redux-toastr';

/**
 * Returns a function that shows a toast of the given type.
 *
 * @param {('error' | 'info')} [type='error'] - The type of the toast to show.
 * @returns {Function} - Function that shows a toast.
 * @static
 */
const show = (type = 'error') => {
  return (toast) => {
    const options = {
      className: `ody-toast ${ type }`,
    };
    const title = '';
    toastr.light(title, toast, options);
  };
};

/**
 * Shows the given error toast.
 *
 * @param {string} toast - The toast to show.
 * @returns {void}
 * @static
 */
const error = show('error');

/**
 * Shows the given information toast.
 *
 * @param {string} toast - The toast to show.
 * @returns {void}
 * @static
 */
const info = show('info');

export {
  error,
  info,
};
