/**
 * Resource method to request a password reset.
 *
 * @module resources/auth/request-password-reset
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/auth/request-password-reset~Params
 * @type {object}
 * @property {string} email - The email.
 */

/**
 * Requests a password reset based on the given parameters.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/auth/request-password-reset~Params} params - The parameters.
 * @returns {Promise<void>} - The promise.
 * @static
 */
const requestPasswordReset = (params) => {
  return axios.request({
    data: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'post',
    url: '/auth/password-reset/request',
  }).then((response) => {
    return response.data;
  });
};

export default requestPasswordReset;
