/**
 * User permissions.
 *
 * @module utilities/auth/permissions
 */

/**
 * Permission to find talent.
 *
 * @constant {string}
 * @static
 */
export const FIND_TALENT = 'FIND_TALENT';

/**
 * Permission to find work.
 *
 * @constant {string}
 * @static
 */
export const FIND_WORK = 'FIND_WORK';

export default [
  FIND_TALENT,
  FIND_WORK,
];
