import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import env from 'config/env';
import LinkedIn from 'images/linkedin.png';

const LinkedInOAuthButton = memo((props) => {
  const { buttonLabel, className, handleLinkedinSign } = props;

  const { linkedInLogin } = useLinkedIn({
    clientId: env.LINKEDIN_CLIENT_ID,
    onSuccess: handleLinkedinSign,
    redirectUri: `${ env.PUBLIC_URL }/linkedin`,
    scope: 'r_emailaddress,r_liteprofile',
  });

  return (
    <button
      className={
        clsx('btn btn-custom btn-rounded-sm btn-white', className)
      }
      onClick={ linkedInLogin }
      type='button'
    >
      <img alt='LinkedIn' src={ LinkedIn } />
      { buttonLabel }
    </button>
  );
});

LinkedInOAuthButton.displayName = 'LinkedInOAuthButton';

LinkedInOAuthButton.propTypes = {
  // The button label
  buttonLabel: PropTypes.string.isRequired,
  // The className of the button
  className: PropTypes.string,
  // The function (authorizationCode) => void to be called when logging successfully in linkedin
  handleLinkedinSign: PropTypes.func.isRequired,
};

LinkedInOAuthButton.defaultProps = {};

export default LinkedInOAuthButton;
