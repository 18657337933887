import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useRef } from 'react';

import useElementOnScreen from 'hooks/use-element-on-screen';

const LottieAnimationContainer = memo((props) => {
  const { className, animationData, animatedIconClassName, loop } = props;

  const animationLottieRef = useRef();

  const [ containerRef, isVisible ] = useElementOnScreen({
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!isVisible) {
      return animationLottieRef.current?.stop();
    }
    return animationLottieRef.current?.play();
  }, [ isVisible ]);

  return (
    <div
      className={ className }
      ref={ containerRef }
    >
      <Lottie
        animationData={ animationData }
        className={ animatedIconClassName }
        loop={ loop }
        lottieRef={ animationLottieRef }
      />
    </div>
  );
});

LottieAnimationContainer.displayName = 'LottieAnimationContainer';

LottieAnimationContainer.propTypes = {
  // The animated icon className
  animatedIconClassName: PropTypes.string.isRequired,
  // The lottie animation json
  animationData: PropTypes.object.isRequired,
  // the styles for the container
  className: PropTypes.string.isRequired,
  // Whether the animation is in a loop
  loop: PropTypes.bool,
};

LottieAnimationContainer.defaultProps = {
  loop: true,
};

export default LottieAnimationContainer;
