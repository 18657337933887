/**
 * Hooks for use form validation.
 *
 * @module hooks/use-form-with-validation
 */
import { joiResolver } from '@hookform/resolvers/joi';
import * as Joi from 'joi';
import { useForm } from 'react-hook-form';

/**
 * This function is type inference ready and will auto-validate the useForm with the proper values.
 *
 * If you don't already have the schema or use a dynamic schema, consider useFormWithSchemaBuilder()
 *
 * @param {object} schema - A valid joi schema
 * @param {object} useFormProps - The use form props hook
 * @returns {any} - The use form return schema with the validations provided
 * @static
 */
const useFormWithSchema = (schema, useFormProps = {}) => {
  return useForm({
    ...useFormProps,
    reValidateMode: 'onChange',
    resolver: joiResolver(schema),
  });
};

/**
 * Use this to prevent importing joi library in your components, if you don't already have the schema,
 * and if you want to have a dynamic schema values.
 * This is rebuild on each render.
 * This function is type inference ready and will auto-validate the useForm with the proper values.
 *
 * If you have already the schema or use a static schema, consider useFormWithSchema()
 *
 * @param {function} schemaBuilder - The function (joi: typeof Joi) => any.
 * Should return a validation schema through a generated function
 * @param {object} useFormProps - The use form props hook Do not provide "resolver" value as it will be ignored.
 * @returns {any} - The useForm return schema with the validations provided
 */
const useFormWithSchemaBuilder = (schemaBuilder, useFormProps = {}) => {
  return useForm({
    ...useFormProps,
    reValidateMode: 'onChange',
    resolver: joiResolver(schemaBuilder(Joi)),
  });
};

export {
  useFormWithSchema,
  useFormWithSchemaBuilder,
};
