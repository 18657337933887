/**
 * Duck for requests.
 *
 * @module ducks/requests
 */

/**
 * The type of actions to describe that a request ended.
 *
 * @constant {string}
 * @default
 * @static
 */
const REQUEST_ENDED = 'REQUEST_ENDED';

/**
 * The type of actions to describe that a request was made.
 *
 * @constant {string}
 * @default
 * @static
 */
const REQUEST_MADE = 'REQUEST_MADE';

/**
 * The type of actions to describe that a request started.
 *
 * @constant {string}
 * @default
 * @static
 */
const REQUEST_STARTED = 'REQUEST_STARTED';

/**
 * Creates an action to end the currently active request.
 *
 * @returns {object} - The action.
 * @static
 */
const endRequest = () => {
  return {
    type: REQUEST_ENDED,
  };
};

/**
 * Creates an action to make a request based on the given configuration.
 *
 * @param {function} method - The resource method to invoke.
 * @param {object} params - The parameters to pass to the resource method.
 * @param {object} [options] - Extra options.
 * @param {function} [options.navigate] - The function to invoke to change the URL.
 * @param {function} [options.onCompletion] - The function to invoke when the request completes (successfully or not).
 * @param {function} [options.onSuccess] - The function to invoke when the request succeeds.
 * @param {function} [options.onFailure] - The function to invoke when the request fails.
 * @returns {object} - The action.
 * @static
 */
const request = (method, params, options) => {
  return {
    method,
    options,
    params,
    type: REQUEST_MADE,
  };
};

/**
 * Creates an action to start a request.
 *
 * @returns {object} - The action.
 * @static
 */
const startRequest = () => {
  return {
    type: REQUEST_STARTED,
  };
};

/**
 * Requests state.
 *
 * @typedef module:ducks/requests~State
 * @type {object}
 * @property {boolean} active - Whether there is an active request.
 */

/**
 * The initial requests state.
 *
 * @type {module:ducks/requests~State}
 * @static
 */
const initialState = {
  active: false,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/requests~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/requests~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case REQUEST_ENDED:
    return {
      active: false,
    };
  case REQUEST_STARTED:
    return {
      active: true,
    };
  default:
    return state;
  }
};

const actions = {
  endRequest,
  request,
  startRequest,
};

const types = {
  REQUEST_MADE,
};

export {
  actions,
  reducer,
  types,
};
