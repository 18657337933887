/**
 * Duck for settings.
 *
 * @module ducks/settings
 */
import { DEFAULT_LANGUAGE } from 'i18n';

/**
 * The type of actions to describe that the email of the signed-in user was set.
 *
 * @constant {string}
 * @default
 * @static
 */
const EMAIL_SET = 'EMAIL_SET';

/**
 * The type of actions to describe that the application language was set.
 *
 * @constant {string}
 * @default
 * @static
 */
const LANGUAGE_SET = 'LANGUAGE_SET';

/**
 * The type of actions to describe that the settings were reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const SETTINGS_RESET = 'SETTINGS_RESET';

/**
 * The type of actions to describe that the settings were set.
 *
 * @constant {string}
 * @default
 * @static
 */
const SETTINGS_SET = 'SETTINGS_SET';

/**
 * Creates an action to reset the settings.
 *
 * @returns {object} - The action.
 * @static
 */
const resetSettings = () => {
  return {
    type: SETTINGS_RESET,
  };
};

/**
 * Creates an action to set the given email as the email of the signed-in user.
 *
 * @param {string} email - The email.
 * @returns {object} - The action.
 * @static
 */
const setEmail = (email) => {
  return {
    email,
    type: EMAIL_SET,
  };
};

/**
 * Creates an action to set the given language as the application language.
 *
 * @param {string} language - The language.
 * @returns {object} - The action.
 * @static
 */
const setLanguage = (language) => {
  return {
    language,
    type: LANGUAGE_SET,
  };
};

/**
 * Creates an action to set the given settings as the application settings.
 *
 * @param {object} settings - The settings.
 * @param {string} settings.email - The email of the signed-in user.
 * @param {string} settings.language - The application language.
 * @returns {object} - The action.
 * @static
 */
const setSettings = (settings) => {
  return {
    ...settings,
    type: SETTINGS_SET,
  };
};

/**
 * Settings state.
 *
 * @typedef module:ducks/settings~state
 * @type {object}
 * @property {string} [email] - The email of the signed-in user.
 * @property {string} language - The application language.
 */

/**
 * The initial settings state.
 *
 * @type {module:ducks/settings~State}
 * @static
 */
const initialState = {
  email: undefined,
  language: navigator.language.split('-')[0].toLowerCase() || DEFAULT_LANGUAGE,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/settings~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/settings~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case EMAIL_SET:
    return {
      ...state,
      email: action.email,
    };
  case LANGUAGE_SET:
    return {
      ...state,
      language: action.language,
    };
  case SETTINGS_RESET:
    return {
      email: undefined,
      language: navigator.language.split('-')[0].toLowerCase() || DEFAULT_LANGUAGE,
    };
  case SETTINGS_SET:
    return {
      ...state,
      email: action.email,
      language: action.language,
    };
  default:
    return state;
  }
};

const actions = {
  resetSettings,
  setEmail,
  setLanguage,
  setSettings,
};

const types = {
};

export {
  actions,
  reducer,
  types,
};
