import { useGoogleLogin } from '@react-oauth/google';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import Google from 'images/google.png';
import * as toasts from 'toasts';

const GoogleOAuthButton = memo((props) => {
  const { handleGoogleSign, buttonLabel, className } = props;

  const handleGoogleAuthSuccess = useCallback((data) => {
    return handleGoogleSign(data.access_token, data.token_type);
  }, [ handleGoogleSign ]);

  const handleGoogleAuthError = useCallback((error) => {
    toasts.error(error?.error_description || error?.error_uri);
  }, []);

  const handleNonOAuthError = useCallback((nonOAuthError) => {
    toasts.error(nonOAuthError?.message);
  }, []);

  const handleGoogleAuth = useGoogleLogin({
    flow: 'implicit',
    onError: handleGoogleAuthError,
    onNonOAuthError: handleNonOAuthError,
    onSuccess: handleGoogleAuthSuccess,
  });

  return (
    <button
      className={
        clsx('btn btn-custom btn-rounded-sm btn-white', className)
      }
      onClick={ handleGoogleAuth }
      type='button'
    >
      <img alt='Google' src={ Google } />
      { buttonLabel }
    </button>
  );
});

GoogleOAuthButton.displayName = 'GoogleOAuthButton';

GoogleOAuthButton.propTypes = {
  // The button label
  buttonLabel: PropTypes.string.isRequired,
  // The className for the button
  className: PropTypes.string,
  // The function (accessToken, tokenType) => void to be passed from parent to handle the Google sign verification
  handleGoogleSign: PropTypes.func.isRequired,
};

GoogleOAuthButton.defaultProps = {};

export default GoogleOAuthButton;
