import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import PublicFAQItem from './public-faq-item/public-faq-item';

import './public-faqs-collection.scss';

const PublicFAQCollection = memo((props) => {
  const { faqs } = props;

  // The rendered faqs components
  const renderedFAQsComponents = useMemo(() => {
    return faqs.map((faq) => {
      return (
        <PublicFAQItem
          answer={ faq.answer }
          key={ faq.question }
          question={ faq.question }
        />
      );
    });
  }, [ faqs ]);

  return (
    <div className='public-faq-collection'>
      { renderedFAQsComponents }
    </div>
  );
});

PublicFAQCollection.displayName = 'PublicFAQCollection';

PublicFAQCollection.propTypes = {
  // The faqs array
  faqs: PropTypes.arrayOf(
    PropTypes.shape({
      // The answer
      answer: PropTypes.string,
      // The question
      question: PropTypes.string,
    })
  ).isRequired,
};

PublicFAQCollection.defaultProps = {};

export default PublicFAQCollection;
