/**
 * User roles.
 *
 * @module utilities/auth/roles
 */

/**
 * Administrators.
 *
 * @constant {string}
 * @static
 */
export const ADMINISTRATOR = 'ADMINISTRATOR';

/**
 * Company agents.
 *
 * @constant {string}
 * @static
 */
export const COMPANY_AGENT = 'COMPANY_AGENT';

/**
 * Endorsers.
 *
 * @constant {string}
 * @static
 */
export const ENDORSER = 'ENDORSER';

/**
 * Job seekers.
 *
 * @constant {string}
 * @static
 */
export const JOB_SEEKER = 'JOB_SEEKER';

export default [
  ADMINISTRATOR,
  COMPANY_AGENT,
  ENDORSER,
  JOB_SEEKER,
];
