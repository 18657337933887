/**
 * Resource method to get the user that is currently signed-in.
 *
 * @module resources/users/approve-pending
 */
import axios from 'axios';

/**
 * Params.
 *
 * @typedef module:resources/users/approve-pending~Params
 * @type {object}
 * @property {string} id - The ID of the company agent.
 */

/**
 * Result.
 *
 * @typedef module:resources/users/approve-pending~Result
 * @type {object}
 * @property {string} id - The ID of the company agent that had been approved.
 */

/**
 * Approves a pending user.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @param {module:resources/users/approve-pending~Params} params - The parameters.
 * @returns {Promise<module:resources/users/approve-pending~Result>} - The promise.
 * @static
 */
const approvePending = (params) => {
  return axios.request({
    method: 'post',
    url: `/users/${ params.id }/approve`,
  }).then((response) => {
    return response.data;
  });
};

export default approvePending;
