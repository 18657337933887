/**
 * Resource method to refresh the access token.
 *
 * @module resources/auth/refresh-access-token
 */
import axios from 'axios';

/**
 * Result.
 *
 * @typedef module:resources/auth/refresh-access-token~Result
 * @type {object}
 * @property {string} accessToken - The new access token.
 */

/**
 * Refreshes the access token.
 *
 * This function returns a promise that is either fulfilled with a result or rejected with an error.
 *
 * @returns {Promise<module:resources/auth/refresh-access-token~Result>} - The promise.
 * @static
 */
const refreshAccessToken = () => {
  return axios.request({
    method: 'post',
    url: '/auth/access-token/refresh',
  }).then((response) => {
    return response.data;
  });
};

export default refreshAccessToken;
