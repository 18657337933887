/**
 * Hooks for intersection observer when element on screen.
 *
 * @module hooks/use-element-on-screen
 */
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This function utilizes Intersection observer to observe when an element is in the dom.
 *
 * @param {object} options - A valid joi schema
 * @returns {any} - Returns the visibility of the element and the node ref.
 * @static
 */
const useElementOnScreen = (options) => {
  const containerRef = useRef(null);
  const [ isVisible, setIsVisible ] = useState(false);

  // Function that is triggering the visibility when intersection observer is intersecting
  const callbackFunction = useCallback((entries) => {
    const [ entry ] = entries;
    setIsVisible(entry.isIntersecting);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options);
    const containerElement = containerRef.current;
    if (containerElement) {
      observer.observe(containerElement);
    }
    return () => {
      if (containerElement) {
        observer.unobserve(containerElement);
      }
    };
  }, [ callbackFunction, options ]);

  return [ containerRef, isVisible ];
};

export default useElementOnScreen;
