/**
 * Duck for authn/authz.
 *
 * @module ducks/auth
 */
import * as Permissions from 'utilities/auth/permissions';
import * as Roles from 'utilities/auth/roles';

/**
 * The type of actions to describe that everything related to authn/authz was reset.
 *
 * @constant {string}
 * @default
 * @static
 */
const AUTH_RESET = 'AUTH_RESET';

/**
 * The type of actions to describe that the deactivated flag of the signed-in user was set.
 *
 * @constant {string}
 * @default
 * @static
 */
const DEACTIVATED_SET = 'DEACTIVATED_SET';

/**
 * The type of actions to describe that a user signed in.
 *
 * @constant {string}
 * @default
 * @static
 */
const USER_SIGNED_IN = 'USER_SIGNED_IN';

/**
 * The type of actions to describe that the signed-in user signed out.
 *
 * @constant {string}
 * @default
 * @static
 */
const USER_SIGNED_OUT = 'USER_SIGNED_OUT';

/**
 * Creates an action to reset everything related to authn/authz.
 *
 * @returns {object} - The action.
 * @static
 */
const reset = () => {
  return {
    type: AUTH_RESET,
  };
};

/**
 * Creates an action to set the deactivated flag of the signed-in user to the given value.
 *
 * @param {boolean} deactivated - Whether the signed-in user is deactivated.
 * @returns {object} - The action.
 * @static
 */
const setDeactivated = (deactivated) => {
  return {
    deactivated,
    type: DEACTIVATED_SET,
  };
};

/**
 * Creates an action to sign in the given user.
 *
 * @param {module:types/users~User} user - The user.
 * @returns {object} - The action.
 * @static
 */
const signIn = (user) => {
  // Set the permissions of the user based on their role.
  const { accessToken, deactivated, email, id, language, role, companyId } = user;
  let permissions = [];
  switch (role) {
  case Roles.ADMINISTRATOR:
    permissions.push(Permissions.FIND_TALENT);
    permissions.push(Permissions.FIND_WORK);
    break;
  case Roles.COMPANY_AGENT:
    permissions.push(Permissions.FIND_TALENT);
    break;
  case Roles.ENDORSER:
    permissions.push(Permissions.FIND_TALENT);
    break;
  case Roles.JOB_SEEKER:
    permissions.push(Permissions.FIND_WORK);
    break;
  default:
    break;
  }
  return {
    accessToken,
    companyId,
    deactivated,
    email,
    id,
    language,
    permissions,
    role,
    type: USER_SIGNED_IN,
  };
};

/**
 * Creates an action to sign out the user that is currently signed-in.
 *
 * @returns {object} - The action.
 * @static
 */
const signOut = () => {
  return {
    type: USER_SIGNED_OUT,
  };
};

/**
 * Authn/authz state.
 *
 * @typedef module:ducks/auth~State
 * @type {object}
 * @property {string} companyId - The user's company id
 * @property {boolean} deactivated - Whether the user is deactivated.
 * @property {string} id - The ID of the signed-in user.
 * @property {string[]} permissions - The permissions of the signed-in user.
 * @property {('ADMINISTRATOR' | 'COMPANY_AGENT' | 'ENDORSER' | 'JOB_SEEKER')} role - The role of the signed-in user.
 */

/**
 * The initial authn/authz state.
 *
 * @type {module:ducks/auth~State}
 * @static
 */
const initialState = {
  companyId: undefined,
  deactivated: undefined,
  id: undefined,
  permissions: [],
  role: undefined,
};

/**
 * Applies the given action to the given state and returns the new state.
 *
 * @param {module:ducks/auth~State} state - The previous state.
 * @param {object} action - The action.
 * @returns {module:ducks/auth~State} - The new state.
 * @static
 */
const reducer = (state = initialState, action) => {
  switch (action.type) {
  case AUTH_RESET:
    return {
      ...initialState,
    };
  case DEACTIVATED_SET:
    return {
      ...state,
      deactivated: action.deactivated,
    };
  case USER_SIGNED_IN:
    return {
      ...state,
      companyId: action.companyId,
      deactivated: action.deactivated,
      id: action.id,
      permissions: action.permissions,
      role: action.role,
    };
  case USER_SIGNED_OUT:
    return {
      ...initialState,
    };
  default:
    return state;
  }
};

const actions = {
  reset,
  setDeactivated,
  signIn,
  signOut,
};

const types = {
  AUTH_RESET,
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
};

export {
  actions,
  reducer,
  types,
};
